import React from 'react';
import Thanks from '../components/organisms/WordPress/Thanks';

export default function contact() {

  return (
    <>
      < Thanks />
    </>
  )
}
